import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Fun Run 4
			</title>
			<meta name={"description"} content={"Race, win, and laugh with friends in exciting races full of chaos, strategy, and fun-filled power-ups!"} />
			<meta property={"og:title"} content={"Fun Run 4"} />
			<meta property={"og:description"} content={"Race, win, and laugh with friends in exciting races full of chaos, strategy, and fun-filled power-ups!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66fcf13c28b5e30023ad000d/images/DALL%C2%B7E%202024-10-02%2012.52.10%20-%20A%20simple,%20colorful%20favicon%20for%20'Fun%20Run%204%20-%20Multiplayer%20Games,'%20featuring%20an%20energetic%20animal%20character,%20like%20a%20fox%20or%20rabbit,%20racing%20forward%20in%20a%20dyn.jpg?v=2024-10-02T10:00:46.425Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66fcf13c28b5e30023ad000d/images/DALL%C2%B7E%202024-10-02%2012.52.10%20-%20A%20simple,%20colorful%20favicon%20for%20'Fun%20Run%204%20-%20Multiplayer%20Games,'%20featuring%20an%20energetic%20animal%20character,%20like%20a%20fox%20or%20rabbit,%20racing%20forward%20in%20a%20dyn.jpg?v=2024-10-02T10:00:46.425Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66fcf13c28b5e30023ad000d/images/DALL%C2%B7E%202024-10-02%2012.52.10%20-%20A%20simple,%20colorful%20favicon%20for%20'Fun%20Run%204%20-%20Multiplayer%20Games,'%20featuring%20an%20energetic%20animal%20character,%20like%20a%20fox%20or%20rabbit,%20racing%20forward%20in%20a%20dyn.jpg?v=2024-10-02T10:00:46.425Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66fcf13c28b5e30023ad000d/images/DALL%C2%B7E%202024-10-02%2012.52.10%20-%20A%20simple,%20colorful%20favicon%20for%20'Fun%20Run%204%20-%20Multiplayer%20Games,'%20featuring%20an%20energetic%20animal%20character,%20like%20a%20fox%20or%20rabbit,%20racing%20forward%20in%20a%20dyn.jpg?v=2024-10-02T10:00:46.425Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66fcf13c28b5e30023ad000d/images/DALL%C2%B7E%202024-10-02%2012.52.10%20-%20A%20simple,%20colorful%20favicon%20for%20'Fun%20Run%204%20-%20Multiplayer%20Games,'%20featuring%20an%20energetic%20animal%20character,%20like%20a%20fox%20or%20rabbit,%20racing%20forward%20in%20a%20dyn.jpg?v=2024-10-02T10:00:46.425Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66fcf13c28b5e30023ad000d/images/DALL%C2%B7E%202024-10-02%2012.52.10%20-%20A%20simple,%20colorful%20favicon%20for%20'Fun%20Run%204%20-%20Multiplayer%20Games,'%20featuring%20an%20energetic%20animal%20character,%20like%20a%20fox%20or%20rabbit,%20racing%20forward%20in%20a%20dyn.jpg?v=2024-10-02T10:00:46.425Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66fcf13c28b5e30023ad000d/images/DALL%C2%B7E%202024-10-02%2012.52.10%20-%20A%20simple,%20colorful%20favicon%20for%20'Fun%20Run%204%20-%20Multiplayer%20Games,'%20featuring%20an%20energetic%20animal%20character,%20like%20a%20fox%20or%20rabbit,%20racing%20forward%20in%20a%20dyn.jpg?v=2024-10-02T10:00:46.425Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66fbc4301b94b10024315de0"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});